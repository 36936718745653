import React from "react";
import Header from "../src/components/header/header";
import Hero from "../src/components/hero/hero";
import OurProjects from "./components/ourProjects/ourProjects";
import OurProjectsList from "./components/ourProjectsList/ourProjectList";
import OurServices from "./components/ourServices/ourServices";
import AboutUs from "../src/components/aboutUs/aboutUs";
import FAQ from "../src/components/faq/faq";
import FindProperty from "../src/components/findProperty/findProperty";
import Footer from "../src/components/footer/footer";
import { Provider, useDispatch } from "react-redux";
import { store } from "./store/store";
import ContactUsWrapper from "./components/contactUsWrapper";
import { UPDATE_MODAL } from "./store/actionsName";

import NewModal from "./components/newModal/newModal";

const App = () => {
    const dispatch = useDispatch();

    const modalToggle = () => dispatch({type:UPDATE_MODAL})

  return (
    <>
        <Header modalToggle={modalToggle} />
        <Hero modalToggle={modalToggle} />
        <OurProjects />
        <OurProjectsList modalToggle={modalToggle} />
        <OurServices />
        <AboutUs modalToggle={modalToggle} />
        <FAQ />
        <FindProperty modalToggle={modalToggle} />
        <Footer />
        <ContactUsWrapper modalToggle={modalToggle} />

        <NewModal />
    </>
  );
};

export default App;
