import React from "react";
import { useSelector } from "react-redux";
import ContactUs from "./contactUs/contactUs";

const ContactUsWrapper = ({ modalToggle }) => {
  const { updateModal } = useSelector((state) => state.generalReducer);
  return <>{updateModal && <ContactUs modalToggle={modalToggle} />}</>;
};

export default ContactUsWrapper;
