import React from "react";
import "./style.css";


function OurProjectsList({ modalToggle }) {
  const bodyClass = () => {
    // 👇️ add class to the body element
    document.body.classList.add("modal-active");

    // 👇️ toggle class on the body element
    // document.body.classList.toggle('bg-salmon');
  };
  return (
    <>
      <div className="ourProjectsList">
        <div className="container z-index-2">
          <div className="ourProjectsList-wrapper">
            <div className="main-page-title">Наши проекты</div>
            <div className="ourProjectsList-subtitle">
              <h2>
                Более 730 объектов недвижимости в Турции, которые позволяют нам
                оправдать ваши ожидания и подобрать подходящую собственность.
              </h2>
            </div>
            <div className="ourProjectsList-search">
              <div className="ourProjectList-search-items">
                <div className="ourProjectList-search-item">
                  <div className="ourProjectList-search-item-title">
                    <h2>Location</h2>
                  </div>
                  <div className="ourProjectList-search-item-input">
                    <button>İstanbul</button>
                  </div>
                </div>
                <div className="ourProjectList-search-item">
                  <div className="ourProjectList-search-item-title">
                    <h2>Type</h2>
                  </div>
                  <div className="ourProjectList-search-item-input">
                    <button>Not Selected</button>
                  </div>
                </div>
                <div className="ourProjectList-search-item">
                  <div className="ourProjectList-search-item-title">
                    <h2>Room</h2>
                  </div>
                  <div className="ourProjectList-search-item-input">
                    <button>Not Selected</button>
                  </div>
                </div>
                <div className="ourProjectList-search-item">
                  <div className="ourProjectList-search-item-title">
                    <h2>m²</h2>
                  </div>
                  <div className="ourProjectList-search-item-input">
                    <button>Not Selected</button>
                  </div>
                </div>
                <div className="ourProjectList-search-item">
                  <div className="ourProjectList-search-item-title">
                    <h2>Price</h2>
                  </div>
                  <div className="ourProjectList-search-item-input">
                    <button>Not Selected</button>
                  </div>
                </div>
                <div className="ourProjectList-search-item">
                  <button className="search-btn">Search</button>
                </div>
              </div>
            </div>
            <div className="pagination-wrapper"></div>
            <div className="ourProjectList-list-wrapper">
              <div className="ourProjectList-list-item">
                <div className="ourProjectList-list-item-pic">
                  <picture>
                    <source
                      srcset="/images/ourprojects/baharyaka.webp"
                      type="image/webp"
                    />
                    <img src="/images/ourprojects/baharyaka.jpg" alt="" />
                  </picture>
                </div>
                <div className="ourProjectList-list-item-info">
                  <div className="ourProjectList-list-item-info-wrapper">
                    <div className="ourProjectList-item-info-title">
                      <h1>Апартаменты с видом на Золотой Рог</h1>
                    </div>
                    <div className="ourProjectList-item-info-description">
                      <h2>
                        Самый крупный ЖК в районе. Строится по соседству с лесом
                        и парковой зоной. Свежий воздух и безопасность в
                        непосредственной близости от центра города.
                      </h2>
                    </div>
                    <div className="ourProjectList-item-info-details">
                      <span>102 м2</span>
                      <span> 2+1</span>
                      <span>гражданство </span>
                    </div>
                    <div className="ourProjectList-item-info-price-and-btn">
                      <div className="ourProjectList-item-info-price-and-location">
                        <div className="ourProjectList-item-info-price">
                          <span> От 539.000$ </span>
                        </div>
                        <div className="ourProjectList-item-info-location">
                          <span>Стамбул, Эюпсултан</span>
                        </div>
                      </div>
                      <div className="ourProjectList-item-info-btn-wrapper">
                        <button
                          onClick={function (event) {
                            modalToggle();
                            bodyClass();
                          }}
                        >
                          купить
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="ourProjectList-list-item">
                <div className="ourProjectList-list-item-pic">
                <picture>
                    <source
                      srcset="/images/ourprojects/bahcelievler.webp"
                      type="image/webp"
                    />
                    <img src="/images/ourprojects/bahcelievler.jpg" alt="" />
                  </picture>
                </div>
                <div className="ourProjectList-list-item-info">
                  <div className="ourProjectList-list-item-info-wrapper">
                    <div className="ourProjectList-item-info-title">
                      <h1>Роскошные апартаменты в ЖК семейной концепции</h1>
                    </div>
                    <div className="ourProjectList-item-info-description">
                      <h2>
                        Люксовый образ жизни и безусловный комфорт от надежного
                        застройщика. Тихий зеленый район, собственное озеро и
                        лес. Блоки ЖК до 7 этажей.
                      </h2>
                    </div>
                    <div className="ourProjectList-item-info-details">
                      <span>106 м2</span>
                      <span>2+1 </span>
                      <span>гражданство</span>
                    </div>
                    <div className="ourProjectList-item-info-price-and-btn">
                      <div className="ourProjectList-item-info-price-and-location">
                        <div className="ourProjectList-item-info-price">
                          <span>От 539.000$</span>
                        </div>
                        <div className="ourProjectList-item-info-location">
                          <span>Стамбул, Бахчелиевлер</span>
                        </div>
                      </div>
                      <div className="ourProjectList-item-info-btn-wrapper">
                        <button
                          onClick={function (event) {
                            modalToggle();
                            bodyClass();
                          }}
                        >
                          купить
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="ourProjectList-list-item">
                <div className="ourProjectList-list-item-pic">
                <picture>
                    <source
                      srcset="/images/ourprojects/besiktas.webp"
                      type="image/webp"
                    />
                   <img src="/images/ourprojects/besiktas.jpg" alt="" />
                  </picture>
                  
                </div>
                <div className="ourProjectList-list-item-info">
                  <div className="ourProjectList-list-item-info-wrapper">
                    <div className="ourProjectList-item-info-title">
                      <h1>
                        Люксовые апартаменты в самом центре Стамбула с видом на
                        Босфор
                      </h1>
                    </div>
                    <div className="ourProjectList-item-info-description">
                      <h2>
                        Эксклюзивный проект со всей внутренней инфраструктурой
                        для вашего комфорта. Строится по соседству с важными
                        государственными зданиями, что обеспечивает абсолютную
                        безопасность
                      </h2>
                    </div>
                    <div className="ourProjectList-item-info-details">
                      <span>131 м2 </span>
                      <span>2,5+1 </span>
                      <span>гражданство</span>
                    </div>
                    <div className="ourProjectList-item-info-price-and-btn">
                      <div className="ourProjectList-item-info-price-and-location">
                        <div className="ourProjectList-item-info-price">
                          <span>От 1.040.681$</span>
                        </div>
                        <div className="ourProjectList-item-info-location">
                          <span>Стамбул, Бешикташ</span>
                        </div>
                      </div>
                      <div className="ourProjectList-item-info-btn-wrapper">
                        <button
                          onClick={function (event) {
                            modalToggle();
                            bodyClass();
                          }}
                        >
                          купить
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="ourProjectList-list-item">
                <div className="ourProjectList-list-item-pic">
                <picture>
                    <source
                      srcset="/images/ourprojects/houseB.webp"
                      type="image/webp"
                    />
                   <img src="/images/ourprojects/houseB.jpg" alt="" />
                  </picture>
                 
                </div>
                <div className="ourProjectList-list-item-info">
                  <div className="ourProjectList-list-item-info-wrapper">
                    <div className="ourProjectList-item-info-title">
                      <h1>
                        Проект в морокканском стиле от дизайнера отеля Adam &
                        Eve
                      </h1>
                    </div>
                    <div className="ourProjectList-item-info-description">
                      <h2>
                        Первый проект для иностранцев в одном из самых
                        престижных районов Стамбула рядом с Белградским лесом.
                        Проект на этапе строительства по самой выгодной цене.
                      </h2>
                    </div>
                    <div className="ourProjectList-item-info-details">
                      <span>66 м2</span>
                      <span>1+1</span>
                      <span>гражданство </span>
                    </div>
                    <div className="ourProjectList-item-info-price-and-btn">
                      <div className="ourProjectList-item-info-price-and-location">
                        <div className="ourProjectList-item-info-price">
                          <span> От 400.000$</span>
                        </div>
                        <div className="ourProjectList-item-info-location">
                          <span>Стамбул, Гёктюрк</span>
                        </div>
                      </div>
                      <div className="ourProjectList-item-info-btn-wrapper">
                        <button
                          onClick={function (event) {
                            modalToggle();
                            bodyClass();
                          }}
                        >
                          купить
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="ourProjectList-list-item">
                <div className="ourProjectList-list-item-pic">
                <picture>
                    <source
                      srcset="/images/ourprojects/lamerdragos.webp"
                      type="image/webp"
                    />
                  <img src="/images/ourprojects/lamerdragos.jpg" alt="" />
                  </picture>
                  
                </div>
                <div className="ourProjectList-list-item-info">
                  <div className="ourProjectList-list-item-info-wrapper">
                    <div className="ourProjectList-item-info-title">
                      <h1>
                        Доступный вариант для инвестиций в самом сердце Азии
                      </h1>
                    </div>
                    <div className="ourProjectList-item-info-description">
                      <h2>
                        Единственный проект, предлагающий студии в Стамбуле. Все
                        квартиры с балконом от 12 метров. С 6-го этажа
                        открывается вид на море. От застройщика ЖК “Два
                        Капитана” в Новосибирске и Научного парка МГУ в Москве.
                      </h2>
                    </div>
                    <div className="ourProjectList-item-info-details">
                      <span> 47 м2</span>
                      <span> 1+0</span>
                      <span>ВНЖ </span>
                    </div>
                    <div className="ourProjectList-item-info-price-and-btn">
                      <div className="ourProjectList-item-info-price-and-location">
                        <div className="ourProjectList-item-info-price">
                          <span> От 165.000$</span>
                        </div>
                        <div className="ourProjectList-item-info-location">
                          <span> Стамбул, Мальтепе </span>
                        </div>
                      </div>
                      <div className="ourProjectList-item-info-btn-wrapper">
                        <button
                          onClick={function (event) {
                            modalToggle();
                            bodyClass();
                          }}
                        >
                          купить
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="ourProjectList-list-item">
                <div className="ourProjectList-list-item-pic">
                <picture>
                    <source
                      srcset="/images/ourprojects/temeltas.webp"
                      type="image/webp"
                    />
                 <img src="/images/ourprojects/temeltas.jpg" alt="" />
                  </picture>
                  
                </div>
                <div className="ourProjectList-list-item-info">
                  <div className="ourProjectList-list-item-info-wrapper">
                    <div className="ourProjectList-item-info-title">
                      <h1>
                        Бюджетные апартаменты в отдалении от шума мегаполиса
                      </h1>
                    </div>
                    <div className="ourProjectList-item-info-description">
                      <h2>
                        Резиденция с удобной социальной инфраструктурой,
                        строящаяся в центре удобной транспортной развязки. По
                        соседствую располагаются парковые зоны и естественное
                        озеро.
                      </h2>
                    </div>
                    <div className="ourProjectList-item-info-details">
                      <span> 80 м2 </span>
                      <span>1+1 </span>
                      <span>ВНЖ</span>
                    </div>
                    <div className="ourProjectList-item-info-price-and-btn">
                      <div className="ourProjectList-item-info-price-and-location">
                        <div className="ourProjectList-item-info-price">
                          <span>400.000 ₺</span>
                        </div>
                        <div className="ourProjectList-item-info-location">
                          <span>Буюкчекмедже, Стамбул </span>
                        </div>
                      </div>
                      <div className="ourProjectList-item-info-btn-wrapper">
                        <button
                          onClick={function (event) {
                            modalToggle();
                            bodyClass();
                          }}
                        >
                          купить
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="pagination-wrapper"></div>
          </div>
        </div>
      </div>
    </>
  );
}
export default OurProjectsList;
