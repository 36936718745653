import React from "react";
import { Accordion, AccordionItem } from "react-light-accordion";
import "react-light-accordion/demo/css/index.css";
import "./style.css";

class FAQ extends React.Component {
  render() {
    return (
      <div className="faq">
        <div className="faq-content">
          <div className="main-page-title">FAQ’ s</div>
          <div className="faq-text">
            Если вы не нашли свой ответ здесь, свяжитесь с нами напрямую.
          </div>
        </div>
        <div className="faq-wrapper">
          <div className="container">
            <Accordion atomic={true}>
              <AccordionItem title=" Как получить турецкое гражданство?">
                <p style={{ padding: "18px 0 18px 0" }}>
                  Вы можете получить турецкое гражданство через инвестиции на
                  сумму от 400.000 долларов США и с обязательством не продавать
                  недвижимость в течение 3 лет.
                </p>
              </AccordionItem>

              <AccordionItem title="Каковы условия получения турецкого гражданства?">
                <p style={{ padding: "18px 0 18px 0" }}>
                  1. Вы должны купить недвижимость у гражданина Турции или
                  турецкого юридического лица — турецкого застройщика или
                  агентства недвижимости. 2. У вашей собственности должен быть
                  готовый документ о праве собственности. 3. Недвижимость должна
                  стоить не менее 400.000 долларов США 4. Оплата должна быть
                  произведена путем перевода денег через турецкий банк. 5. Вы не
                  должны продавать недвижимость в течение следующих 3 лет.
                </p>
              </AccordionItem>

              <AccordionItem title=" Как будет рассчитываться курс доллара США при покупке недвижимости?  ">
                <p style={{ padding: "18px 0 18px 0" }}>
                  Курс доллара США будет таким же, как официальный курс на дату
                  покупки, согласно бюллетеням Центрального банка Турции.
                </p>
              </AccordionItem>
              <AccordionItem title="Сколько объектов недвижимости нужно приобрести, чтобы получить гражданство?  ">
                <p style={{ padding: "18px 0 18px 0" }}>
                  Вы можете купить один или несколько объектов недвижимости
                  общей стоимостью от 400.000 долларов США.
                </p>
              </AccordionItem>
            </Accordion>
          </div>
        </div>
      </div>
    );
  }
}

export default FAQ;
