import React from "react";
import "./style.css";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

class ourServices extends React.Component {
  render() {
    return (
      <>
        <div className="services">
          <div className="main-page-title">Сервисы</div>
          <div className="container">
            <div className="services-texts">
              <div className="services-text">
                В нашей команде более 100 сотрудников, которые ежедневно
                предоставляют услуги лучшего качества по всему миру.
              </div>
            </div>
            <div className="services-grid">
              <div className="services-card">
              <LazyLoadImage
                  src={"/images/services-card-1.jpg"}
                  width={600}
                  height={400}
                  effect={"blur"}
                  alt="Image Alt"
                />

                <div className="services-card-overlay"></div>
                <div className="services-card-content">
                  <div className="services-card-title">
                    Юридическая поддержка на 3 языках
                  </div>
                  <div className="services-card-text">
                    Наши сотрудники говорят на более, чем 10 языках и
                    предоставляют юридические услуги на английском, турецком и
                    русском языках.
                  </div>
                </div>
              </div>
              <div className="services-card">
                <LazyLoadImage
                  src={"/images/services-card-2.jpg"}
                  width={600}
                  height={400}
                  effect={"blur"}
                  alt="Image Alt"
                />
                <div className="services-card-overlay"></div>
                <div className="services-card-content">
                  <div className="services-card-title">VIP-сервис</div>
                  <div className="services-card-text">
                    Каждый клиент — VIP-клиент для нас. Мы предоставляем услуги
                    персонального водителя на автомобиле фримиум-класса, услуги
                    переводчика и персонального ассистента.
                  </div>
                </div>
              </div>
              <div className="services-card">
              <LazyLoadImage
                  src={"/images/services-card-3.jpg"}
                  width={600}
                  height={400}
                  effect={"blur"}
                  alt="Image Alt"
                />
                <div className="services-card-overlay"></div>
                <div className="services-card-content">
                  <div className="services-card-title">Бизнес и инвестиции</div>
                  <div className="services-card-text">
                    Мы можем подобрать подходящие варианты коммерческой
                    недвижимости, готовый бизнес, оказать помощь с релокацией
                    или открытием нового бизнеса в Турции.
                  </div>
                </div>
              </div>
              <div className="services-card">
              <LazyLoadImage
                  src={"/images/services-card-4.jpg"}
                  width={600}
                  height={400}
                  effect={"blur"}
                  alt="Image Alt"
                />
                <div className="services-card-overlay"></div>
                <div className="services-card-content">
                  <div className="services-card-title">Денежные операции</div>
                  <div className="services-card-text">
                    Наш финансовый отдел поможет вам перевести деньги из вашей
                    страны в Турцию.
                  </div>
                </div>
              </div>
              <div className="services-card">
              <LazyLoadImage
                  src={"/images/services-card-5.jpg"}
                  width={600}
                  height={400}
                  effect={"blur"}
                  alt="Image Alt"
                />
                <div className="services-card-overlay"></div>
                <div className="services-card-content">
                  <div className="services-card-title">
                    БЕСПЛАТНЫЕ персональные брокер-туры
                  </div>
                  <div className="services-card-text">
                    Мы подбираем подходящие объекты недвижимости, организовываем
                    маршрут и премиальный автомобиль с личным водителем, чтобы
                    вы чувствовали себя комфортно, выбирая дом вашей мечты.
                  </div>
                </div>
              </div>
              <div className="services-card">
              <LazyLoadImage
                  src={"/images/services-card-6.jpg"}
                  width={600}
                  height={400}
                  effect={"blur"}
                  alt="Image Alt"
                />
                <div className="services-card-overlay"></div>
                <div className="services-card-content">
                  <div className="services-card-title">Удаленная покупка</div>
                  <div className="services-card-text">
                    Если вы не хотите приезжать в Турцию, мы можем провести
                    сделку удаленно и совершенно безопасно.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
export default ourServices;
