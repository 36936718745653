import React from "react";
import "./style.css";

function Hero({ modalToggle }) {
  const bodyClass = () => {
    // 👇️ add class to the body element
    document.body.classList.add("modal-active");

    // 👇️ toggle class on the body element
    // document.body.classList.toggle('bg-salmon');
  };

  return (
    <>
      <div className="hero">
        <div className="hero-overlay"></div>
        <div className="hero-inner">
          <div className="hero-left">
            <div className="hero-content">
              <div className="hero-title">
                Feels like <span>home</span>
              </div>
              <div className="hero-text1">
                COSY Life Consulting — агентство по сопровождению иммиграции и
                проведению сделок с недвижимостью на сумму от 150.000 USD.
                Обрести новый дом в Стамбуле теперь легко.
              </div>
              <div className="hero-text2">
                Швейцарское качество - Турецкий сервис - Многоязычная команда
              </div>
              <button
                className="primary-button"
                onClick={function (event) {
                  modalToggle();
                  bodyClass();
                }}
              >
                Подобрать недвижимость
              </button>
              <button className="primary-button">
                <a
                  href="https://wa.me/+905393066910?text=Добрый%20день!%20Я%20интересуюсь%20покупкой%20недвижимости%20в%20Стамбуле."
                  target="_blank"
                >
                  What’s App
                </a>
              </button>
              <div className="hero-istatistics">
                <div className="hero-istatistic">
                  <span>
                    300 <span>+</span>
                  </span>
                  <span>Проектов</span>
                </div>
                <div className="hero-istatistic">
                  <span>
                    730 <span>+</span>
                  </span>
                  <span>Объектов недвижимости</span>
                </div>
                <div className="hero-istatistic">
                  <span>
                    220 <span>+</span>
                  </span>
                  <span>Покупателей</span>
                </div>
              </div>
            </div>
          </div>
          <div className="hero-right">
            <img src="images/hero-banner.png" alt="" />
          </div>
        </div>
        <div className="clouds">
          <img src="/images/clouds/cloud1.png" alt="" style={{ "--i": 1 }} />
          <img src="/images/clouds/cloud2.png" alt="" style={{ "--i": 2 }} />
          <img src="/images/clouds/cloud3.png" alt="" style={{ "--i": 3 }} />
          <img src="/images/clouds/cloud4.png" alt="" style={{ "--i": 4 }} />
          <img src="/images/clouds/cloud5.png" alt="" style={{ "--i": 5 }} />
          <img src="/images/clouds/cloud1.png" alt="" style={{ "--i": 6 }} />
          <img src="/images/clouds/cloud2.png" alt="" style={{ "--i": 7 }} />
          <img src="/images/clouds/cloud3.png" alt="" style={{ "--i": 8 }} />
          <img src="/images/clouds/cloud4.png" alt="" style={{ "--i": 9 }} />
          <img src="/images/clouds/cloud5.png" alt="" style={{ "--i": 10 }} />
          <img src="/images/clouds/cloud1.png" alt="" style={{ "--i": 11 }} />
          <img src="/images/clouds/cloud2.png" alt="" style={{ "--i": 12 }} />
          <img src="/images/clouds/cloud3.png" alt="" style={{ "--i": 13 }} />
          <img src="/images/clouds/cloud4.png" alt="" style={{ "--i": 14 }} />
          <img src="/images/clouds/cloud5.png" alt="" style={{ "--i": 15 }} />
          <img src="/images/clouds/cloud1.png" alt="" style={{ "--i": 16 }} />
          <img src="/images/clouds/cloud2.png" alt="" style={{ "--i": 17 }} />
          <img src="/images/clouds/cloud3.png" alt="" style={{ "--i": 18 }} />
          <img src="/images/clouds/cloud4.png" alt="" style={{ "--i": 19 }} />
          <img src="/images/clouds/cloud5.png" alt="" style={{ "--i": 20 }} />
        </div>
      </div>
    </>
  );
}

export default Hero;
