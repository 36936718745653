import React, { useRef, useState } from "react";
import "./style.css";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";

import emailjs from "@emailjs/browser";

function ContactUs({ modalToggle }) {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");

  const form = useRef();
  const infoPopUp = document.getElementById("infoPopUp");

  const errorMessageId = document.getElementById("errorMessageId");

  const handleChangeName = (event) => {
    setName(event.target.value);
  };
  const handleChangeEmail = (event) => {
    setEmail(event.target.value);
  };

  const bodyClassRemove = () => {
    // 👇️ add class to the body element
    document.body.classList.remove("modal-active");

    // 👇️ toggle class on the body element
    // document.body.classList.toggle('bg-salmon');
  };

  const sendEmail = (e) => {
    e.preventDefault();

    if (
      name.trim().length !== 0 &&
      email.trim().length !== 0 &&
      phoneNumber.trim().length !== 0
    ) {
      emailjs
        .sendForm(
          "service_e7tlsk6",
          "template_q74t02f",
          form.current,
          "_ls_Sjh7mPnmYs8Pu"
        )
        .then(
          (result) => {
            modalToggle();
          },
          (error) => {
            console.log(error.text);
          }
        );
    } else {
      errorMessageId.classList.add("error-message-active");
    }
  };

  return (
    <>
      <div className="contact-us-wrapper">
        <div className="contact-us-closebtn" onClick={modalToggle}>
          <span>X</span>
        </div>
        <div className="contact-us">
          <div className="contact-us-content">
            <div className="contact-us-title">Свяжитесь с нами </div>
            <div className="contact-us-text">
              Чтобы найти место, соответствующее вашим целям и пожеланиям
            </div>
            <h2 id="errorMessageId" className="error-message">
              Please fill in the blanks.
            </h2>
          </div>
          <form ref={form} onSubmit={sendEmail}>
            <label for="name">
              Имя
              <input
                type="text"
                name="user_name"
                placeholder="Имя Фамилия"
                onChange={handleChangeName}
              />
            </label>
            <label for="email">
              E-mail
              <input
                type="email"
                name="user_email"
                placeholder="Ваш e-mail "
                onChange={handleChangeEmail}
              />
            </label>
            <label for="phone">
              Номер телефона
              <PhoneInput
                placeholder="Enter phone number"
                value={phoneNumber}
                onChange={setPhoneNumber}
                name="user_tel"
                type="tel"
                international
                defaultCountry="RU"
              />
            </label>
            <label>
              Как мы можем вам помочь?
              <textarea
                cols="5"
                rows="10"
                name="message"
                placeholder="Ваше сообщение "
              ></textarea>
            </label>
            <input
              type="submit"
              value="Отправить"
              className="primary-button"
            ></input>
          </form>
        </div>
        <div id="infoPopUp" className="info-popUp">
          <div className="info-popUp-content">
            <div className="info-popUp-title">Thank You For Reaching Out!</div>
            <div className="info-popUp-text">
              We will get back to you as soon as possible.
            </div>
          </div>
          <span>✅</span>
        </div>
        <div
          className="contact-us-overlay"
          onClick={function (event) {
            modalToggle();
            bodyClassRemove();
          }}
        ></div>
      </div>
    </>
  );
}
export default ContactUs;
