import React from "react";
import "./style.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebookSquare, faYoutube, faSquareInstagram } from '@fortawesome/free-brands-svg-icons'

class Footer extends React.Component {
  render() {
    return (
      <footer>
        <div className="container">
          <div className="footer-top">
            <div className="footer-left">
              <div className="logo">
                <a href="/">
                <picture>
                <source srcset="/images/logo.webp" type="image/webp" />
                <img src="/images/logo.png" alt="Cosy Life Consulting" />
              </picture>
                </a>
              </div>
              <div className="footer-left-info">
                <a href="tel:+90 539 306 69 10"> +90 539 306 69 10</a>
                <a href="mailto:cosylifelanding@gmail.com">cosylifelanding@gmail.com</a>
              </div>
            </div>
            <div className="footer-right">
              <ul>
                <li>
                  <a href="/">privacy policy</a>
                </li>
                <li>
                  <a href="/">term & condition</a>
                </li>
                <li>
                  <a href="/">about us</a>
                </li>
                <li>
                  <a href="/">faq</a>
                </li>
              </ul>
            </div>
          </div>
          <div className="footer-bot ">
            <div className="footer-left">
              <ul className="footer-socials">
                <li><a href="/"> <FontAwesomeIcon icon={faFacebookSquare} /></a></li>
                <li><a href="/"><FontAwesomeIcon icon={faYoutube} /></a></li>
                <li><a href="/"><FontAwesomeIcon icon={faSquareInstagram} /></a></li>
              </ul>
            </div>
            <div className="footer-right">
              <div className="footer-copy">© 2022 Cosy Life Consulting</div>
            </div>
          </div>
        </div>
      </footer>
    );
  }
}
export default Footer;
