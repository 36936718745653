import React, { useRef, useState } from "react";
import "./style.css";

function NewModal() {


  return (
    <>
        <div className="newModal-wrapper">
            <div className="newModal">
                <div className="newModal-close-btn">
                    <span>X</span>
                </div>
                <div className="newModal-image">
                    <img src="/images/newModalDeneme.jpg" />
                </div>
                <div className="newModal-content">
                    <h2>Ответьте на <strong>5 вопросов</strong> и получите подборку объектов по вашим критериям</h2>
                    <h3>Ответы на вопросы займут не более 25 секунд.</h3>
                    <div className="newModal-btn-wrapper">
                        <button>Начать подбор</button>
                    </div>
                </div>
            </div>
        </div>
    </>
  );
}
export default NewModal;
