import React, { useState } from "react";
import "./style.css";

function Header({ modalToggle }) {
  const [fix, setFix] = useState(false);
  function setFixed() {
    if (window.scrollY >= 100) {
      setFix(true);
    } else {
      setFix(false);
    }
  }
  window.addEventListener("scroll", setFixed);

  const bodyClass = () => {
    // 👇️ add class to the body element
    document.body.classList.add("modal-active");

    // 👇️ toggle class on the body element
    // document.body.classList.toggle('bg-salmon');
  };

  return (
    <>
      <header className={fix ? "header-bg" : ""}>
        <div className="container space-between">
          <div className="logo">
            <a href="/">
              <picture>
                <source srcset="/images/logo.webp" type="image/webp" />
                <img src="/images/logo.png" alt="Cosy Life Consulting" />
              </picture>
            </a>
          </div>
          <div className="header-content">
            <a href="tel:+90 539 306 69 10" target="_blank">
              +90 539 306 69 10
            </a>
            <button
              className="header-btn"
              onClick={function (event) {
                modalToggle();
                bodyClass();
              }}
            >
              Связаться с нами
            </button>
          </div>
        </div>
      </header>
    </>
  );
}

export default Header;
