import React from 'react';
import './style.css';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight, faArrowLeft } from '@fortawesome/free-solid-svg-icons'

import DartIcon from './darticon.svg';

 class OurProjects extends React.Component {

    constructor(props) {
        super(props)
        this.props = props;
        this.state = {
            activeTab: 1,
            activeProject: 1,
        }
    }

  render() {
    return (
        <div className='ourProjects'>
            
            <div className='container'>
                <div className='ourProjects-wrapper'>
                <div className="main-page-title">Наши проекты</div>
                    <div className='ourProjects-subtitle'>
                        <h2>Найдите недвижимость в Стамбуле, соответствующую вашим целям.</h2>
                    </div>
                    <div className='ourProjects-content-wrapper'>
                        <h2 className='mobile-categiroies-title'>Категории</h2>
                        <div className='ourProjects-categories-mobile'>
                            <div className='ourPrejects-categories-mobile-contents'>
                                <div onClick={() => this.setState({activeTab: 1, activeProject: 1})} className={'ourProjects-categories-mobile-item' + (this.state.activeTab == 1 ? "-active" : "" )}>
                                    <img 
                                        src={DartIcon}
                                    />
                                    <h2 className='number'>01</h2>
                                    <div className='ourProjects-categories-item-contents'>
                                        <h3>Стоимость начинается от 165.000$</h3>
                                        <h2>Вид на Жительство</h2>
                                    </div>
                                </div>
                                <div onClick={() => this.setState({activeTab: 2, activeProject: 1})} className={'ourProjects-categories-mobile-item' + (this.state.activeTab == 2? "-active" : "" )}>
                                    <img 
                                        src={DartIcon}
                                    />
                                    <h2 className='number'>02</h2>
                                    <div className='ourProjects-categories-item-contents'>
                                        <h3>Стоимость от 400.000$</h3>
                                        <h2>Гражданство</h2>
                                    </div>
                                </div>
                                <div onClick={() => this.setState({activeTab: 3, activeProject: 1})} className={'ourProjects-categories-mobile-item' + (this.state.activeTab == 3 ? "-active" : "" )}>
                                    <img 
                                        src={DartIcon}
                                    />
                                    <h2 className='number'>03</h2>
                                    <div className='ourProjects-categories-item-contents'>
                                        <h3>Стоимость от 1.000.000$</h3>
                                        <h2>Люкс</h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='ourProjects-categories'>
                            <h2>Категории</h2>
                            <div onClick={() => this.setState({activeTab: 1, activeProject: 1})} className={'ourProjects-categories-item' + (this.state.activeTab == 1 ? "-active" : "" )}>
                                <img 
                                    src={DartIcon}
                                />
                                <h2 className='number'>01</h2>
                                <div className='ourProjects-categories-item-contents'>
                                    <h3>Стоимость начинается от 165.000$</h3>
                                    <h2>Вид на Жительство</h2>
                                </div>
                            </div>
                            <div onClick={() => this.setState({activeTab: 2, activeProject: 1})} className={'ourProjects-categories-item' + (this.state.activeTab == 2 ? "-active" : "" )}>
                                <img 
                                    src={DartIcon}
                                />
                                <h2 className='number'>02</h2>
                                <div className='ourProjects-categories-item-contents'>
                                    <h3>Стоимость от 400.000$</h3>
                                    <h2>Гражданство</h2>
                                </div>
                            </div>
                            <div onClick={() => this.setState({activeTab: 3, activeProject: 1})} className={'ourProjects-categories-item' + (this.state.activeTab == 3 ? "-active" : "" )}>
                                <img 
                                    src={DartIcon}
                                />
                                <h2 className='number'>03</h2>
                                <div className='ourProjects-categories-item-contents'>
                                    <h3>Стоимость от 1.000.000$</h3>
                                    <h2>Люкс</h2>
                                </div>
                            </div>
                        </div>
                        <div className='ourProjects-projects'>

                            {this.state.activeTab == 1 && (
                                <div className='projects-wrapper'>
                                    <div className='project-info'>
                                        {this.state.activeProject == 1 && (
                                            <>
                                                <h2>Бюджетные апартаменты в отдалении от шума мегаполиса</h2>
                                                <h3>Резиденция с удобной социальной инфраструктурой, строящаяся в центре удобной транспортной развязки. По соседствую располагаются парковые зоны и озеро.</h3>
                                            </>                                            
                                        )}
                                        {this.state.activeProject == 2 && (
                                            <>
                                                <h2>Доступный вариант для инвестиций в самом сердце Азии</h2>
                                                <h3>Единственный проект, предлагающий студии в Стамбуле. Все квартиры с балконом от 12 метров. С 6-го этажа открывается вид на море.</h3>
                                            </>                                            
                                        )}
                                        
                                        {this.state.activeProject === 1 && (
                                            <div className='project-info-btns'>
                                                <button className='btn' >
                                                    <FontAwesomeIcon icon={faArrowLeft} />
                                                </button>
                                                <button onClick={() => this.setState({activeProject: 2})} className={'btn-active'}>
                                                    <FontAwesomeIcon icon={faArrowRight} />
                                                </button>
                                            </div>
                                        )}
                                        {this.state.activeProject === 2 && (
                                            <div className='project-info-btns'>
                                                <button onClick={() => this.setState({activeProject: 1})} className={'btn-active'} >
                                                    <FontAwesomeIcon icon={faArrowLeft} />
                                                </button>
                                                <button className={'btn'}>
                                                    <FontAwesomeIcon icon={faArrowRight} />
                                                </button>
                                            </div>
                                        )}

                                        
                                        {this.state.activeProject === 1 && (
                                            <div className='project-info-bars'>
                                                <div className='bar-active'></div>
                                                <div className='bar'></div>
                                            </div>
                                        )}
                                        {this.state.activeProject === 2 && (
                                            <div className='project-info-bars'>
                                                <div className='bar'></div>
                                                <div className='bar-active'></div>
                                            </div>
                                        )}
                                    </div>
                                    <div className='project-pics'>
                                        <div className='project-pic'>
                                            {this.state.activeProject === 1 && (
                                                <img src="/images/ourprojects/temeltas.jpg" />
                                            )}
                                            {this.state.activeProject === 2 && (
                                                <img src="/images/ourprojects/lamerdragos.jpg" />
                                            )}
                                        </div>
                                    </div>
                                </div>
                            )}

                            {this.state.activeTab == 2 && (
                                <div className='projects-wrapper'>
                                    <div className='project-info'>
                                        {this.state.activeProject == 1 && (
                                            <>
                                                <h2>Апартаменты с видом на Золотой Рог</h2>
                                                <h3>Самый крупный ЖК в районе. Строится по соседству с лесом и парковой зоной. Свежий воздух и безопасность.</h3>
                                            </>                                            
                                        )}
                                        {this.state.activeProject == 2 && (
                                            <>
                                                <h2>Роскошные апартаменты в ЖК семейной концепции</h2>
                                                <h3>Люксовый образ жизни и безусловный комфорт от надежного застройщика. Тихий зеленый район, собственное озеро и лес.</h3>
                                            </>                                            
                                        )}
                                        {this.state.activeProject == 3 && (
                                            <>
                                                <h2>Проект в морокканском стиле от дизайнера отеля Adam & Eve</h2>
                                                <h3>Первый проект для иностранцев в одном из самых престижных районов Стамбула рядом с Белградским лесом.</h3>
                                            </>                                            
                                        )}
                                        {this.state.activeProject === 1 && (
                                            <div className='project-info-btns'>
                                                <button className='btn' >
                                                    <FontAwesomeIcon icon={faArrowLeft} />
                                                </button>
                                                <button onClick={() => this.setState({activeProject: 2})} className={'btn-active'}>
                                                    <FontAwesomeIcon icon={faArrowRight} />
                                                </button>
                                            </div>
                                        )}
                                        {this.state.activeProject === 2 && (
                                            <div className='project-info-btns'>
                                                <button onClick={() => this.setState({activeProject: 1})} className={'btn-active'} >
                                                    <FontAwesomeIcon icon={faArrowLeft} />
                                                </button>
                                                <button onClick={() => this.setState({activeProject: 3})} className={'btn-active'}>
                                                    <FontAwesomeIcon icon={faArrowRight} />
                                                </button>
                                            </div>
                                        )}

                                        {this.state.activeProject === 3 && (
                                            <div className='project-info-btns'>
                                                <button onClick={() => this.setState({activeProject: 2})} className={'btn-active'} >
                                                    <FontAwesomeIcon icon={faArrowLeft} />
                                                </button>
                                                <button className={'btn'}>
                                                    <FontAwesomeIcon icon={faArrowRight} />
                                                </button>
                                            </div>
                                        )}
                                        
                                        {this.state.activeProject === 1 && (
                                            <div className='project-info-bars'>
                                                <div className='bar-active'></div>
                                                <div className='bar'></div>
                                                <div className='bar'></div>
                                            </div>
                                        )}
                                        {this.state.activeProject === 2 && (
                                            <div className='project-info-bars'>
                                                <div className='bar'></div>
                                                <div className='bar-active'></div>
                                                <div className='bar'></div>
                                            </div>
                                        )}
                                        {this.state.activeProject === 3 && (
                                            <div className='project-info-bars'>
                                                <div className='bar'></div>
                                                <div className='bar'></div>
                                                <div className='bar-active'></div>
                                            </div>
                                        )}
                                    </div>
                                    <div className='project-pics'>
                                        <div className='project-pic'>
                                            {this.state.activeProject === 1 && (
                                                <img src="/images/ourprojects/baharyaka.jpg" />
                                            )}
                                            {this.state.activeProject === 2 && (
                                                <img src="/images/ourprojects/bahcelievler.jpg" />
                                            )}
                                            {this.state.activeProject === 3 && (
                                                <img src="/images/ourprojects/houseB.jpg" />
                                            )}      
                                        </div>
                                    </div>
                                </div>
                            )}

                            {this.state.activeTab == 3 && (
                                <div className='projects-wrapper'>
                                    <div className='project-info'>
                                        {this.state.activeProject == 1 && (
                                            <>
                                                <h2>Люксовые апартаменты в самом центре Стамбула с видом на Босфор</h2>
                                                <h3>Эксклюзивный проект со всей внутренней инфраструктурой для вашего комфорта. Строится по соседству с важными государственными зданиями, что обеспечивает абсолютную безопасность</h3>
                                            </>
                                        )}
                                        {this.state.activeProject === 1 && (
                                            <div className='project-info-btns'>
                                                <button className='btn' >
                                                    <FontAwesomeIcon icon={faArrowLeft} />
                                                </button>
                                                <button className={'btn'}>
                                                    <FontAwesomeIcon icon={faArrowRight} />
                                                </button>
                                            </div>
                                        )}
                                        
                                        {this.state.activeProject === 1 && (
                                            <div className='project-info-bars'>
                                                <div className='bar-active'></div>
                                            </div>
                                        )}
                                    </div>
                                    <div className='project-pics'>
                                        <div className='project-pic'>
                                            {this.state.activeProject === 1 && (
                                                <img src="/images/ourprojects/besiktas.jpg" />
                                            )}
                                        </div>
                                    </div>
                                </div>
                            )}

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
  }
}
export default OurProjects