import React from "react";
import "./style.css";

function AboutUs({ modalToggle }) {
  const bodyClass = () => {
    // 👇️ add class to the body element
    document.body.classList.add("modal-active");

    // 👇️ toggle class on the body element
    // document.body.classList.toggle('bg-salmon');
  };
  return (
    <>
      <div className="citizenship">
        <div className="container">
          <div className="citizenship-inner">
            <div className="citizenship-content">
              <div className="citizenship-title">Турецкое гражданство</div>
              <div className="citizenship-text"></div>
              <div className="citizenship-item">
                Вы можете получить турецкое гражданство, приобретая недвижимость
                на сумму от 400.000 USD.
              </div>
              <div className="citizenship-item">
                Наша команда будет сопровождать весь процесс сделки и получения
                гражданства.
              </div>
              <div className="citizenship-button">
                <button
                  onClick={function (event) {
                    modalToggle();
                    bodyClass();
                  }}
                  className="primary-button"
                >
                  Свяжитесь с нами
                </button>
              </div>
            </div>
            <div className="citizenship-images">
              <img src="/images/aboutus1.png" alt="" />
              <img src="/images/aboutus2.png" alt="" />
            </div>
          </div>
        </div>
      </div>
      <div className="page-title">
        <div className="container">
          <div className="page-title-inner">
            Гражданство Турции — возможность получить инвесторскую визу в США на
            5 лет и безвизовый въезд в более, чем 110 стран.
          </div>
        </div>
      </div>
      <div className="whyChooseUs">
        <div className="container">
          <div className="whyChooseUs-inner">
            <div className="whyChooseUs-images">
              <img src="/images/aboutus3.jpg" alt="" />
              <img src="/images/aboutus4.jpg" alt="" />
            </div>
            <div className="whyChooseUs-content">
              <div className="whyChooseUs-title">Вид на Жительство</div>
              <div className="whyChooseUs-text"></div>
              <div className="whyChooseUs-item">
                Вы можете получить турецкий ВНЖ, приобретая недвижимость на
                сумму от 75.000 USD в крупном городе и 50.000 USD в маленьком
                городе.
              </div>
              <div className="whyChooseUs-item">
                Мы подберем для вас лучшие варианты, договоримся о цене и
                сопроводим сделку.
              </div>
              <div className="whyChooseUs-button">
                <button
                  onClick={function (event) {
                    modalToggle();
                    bodyClass();
                  }}
                  className="primary-button"
                >
                  Свяжитесь с нами
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="page-title">
        <div className="container">
          <div className="page-title-inner">
            Европейское качество медицины и образования, высокий уровень жизни и
            развивающаяся экономика. Турция — страна для жизни!
          </div>
        </div>
      </div>
      <div className="citizenship">
        <div className="container">
          <div className="citizenship-inner">
            <div className="citizenship-content">
              <div className="citizenship-title">Финансовый советник </div>
              <div className="citizenship-text"></div>
              <div className="citizenship-item">
                Мы поможем вам перевести деньги из вашей страны в турецкий банк,
                подобрать коммерческую недвижимость или готовый бизнес,
                релоцировать ваш бизнес или открыть новый в Турции
              </div>
              <div className="citizenship-item">
                Свяжитесь с нашим специалистом по финансам, чтобы узнать больше
              </div>
              <div className="citizenship-button">
                <button
                  onClick={function (event) {
                    modalToggle();
                    bodyClass();
                  }}
                  className="primary-button"
                >
                  Свяжитесь с нами
                </button>
              </div>
            </div>
            <div className="citizenship-images">
              <img src="/images/aboutus5.jpg" alt="" />
              <img src="/images/aboutus6.jpg" alt="" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default AboutUs;
