import React from "react";
import "./style.css";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

function FindProperty({ modalToggle }) {
  const bodyClass = () => {
    // 👇️ add class to the body element
    document.body.classList.add("modal-active");

    // 👇️ toggle class on the body element
    // document.body.classList.toggle('bg-salmon');
  };

  return (
    <>
      <div className="find-property">
        <div className="container">
          <div className="find-property-inner">
            
            <img src="/images/findproperty.png" alt="" />
            
            <div className="find-property-overlay"></div>
            <div className="find-property-content">
              <div className="find-property-title">
                COSY Life Consulting <br /> надежное агентство недвижимости.
              </div>
              <div className="find-property-text">
                Более 220 довольных клиентов приобрели недвижимость в Турции с
                нами. Станьте одним из них!
              </div>
              <div className="find-property-button">
                <button
                  onClick={function (event) {
                    modalToggle();
                    bodyClass();
                  }}
                  className="primary-button"
                >
                  Подобрать недвижимость
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default FindProperty;
